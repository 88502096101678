@font-face {
  font-family: "Roboto";
  src: url("./fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Nunito";
  src: url("./fonts/Nunito-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: "Ubuntu Condensed";
  src: url("./fonts/UbuntuCondensed-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Ubuntu";
  src: url("./fonts/Ubuntu-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Ubuntu";
  src: url("./fonts/Ubuntu-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Ubuntu";
  src: url("./fonts/Ubuntu-Bold.ttf") format("truetype");
  font-weight: 700;
}

.typographyTypoHeadingDesktopH2 {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.02em;
  text-indent: 0px;
}

.typographyTypoParagraphAllDevicesP {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-indent: 0px;
}
