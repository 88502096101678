.searchBarRow {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.searchBarRow > input {
  width: 100%;
  color: var(--colorBase500);
  padding: 0;
  background: none;
  border: none;
  outline: none;
  font: var(--semInputfieldTypoInputDefault);
}

.searchBarUnderline {
  height: 1px;
  width: 100%;
  background-color: var(--colorBase900);
}

.searchBarContainer.focus > .searchBarUnderline {
  background-color: #ff3d00;
}

.searchIcon {
  fill: none;
  stroke: var(--colorBase500);
}
