.overviewContainer {
  width: 100vw;
  height: 100vh;
}

#userProfile {
  overflow: hidden;
}

.overviewGlobalMenuNavLink {
  margin-top: 12px;
  color: var(--semUserColorWidgetLinkDefault);
  font: var(--semUserWidgetLinkDefault);
}

.overviewContent {
  overflow-y: auto;
  max-height: calc(100% - 64px);
  position: fixed;
  top: 64px;
}

@media only screen and (min-width: 1272px) {
  .overviewContent {
    position: fixed;
    top: 95px;
    right: 0;
    width: 100vw;
    max-height: calc(100% - 95px);
  }
}
