.imprintContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
}

.imprintContents {
  z-index: 20;
  width: calc(100% - 32px);
  height: calc(100% - 32px);
  max-width: 1223px;
  margin: 16px;
  overflow: scroll;
}

.imprintContents > * {
  max-width: 704px;
}

.imprintContainer h1 {
  font-size: 64px;
  line-height: 64px;
}

.imprintContainer h2 {
  margin: 40px 0 16px;
  position: relative;
}

.imprintContainer h2:after {
  content: "";
  width: 80px;
  height: 4px;
  background: var(--colorPrimary200);
  position: absolute;
  bottom: -4px;
  left: 0;
}

.imprintContainer p {
  margin: 0;
}

.imprintLink {
  margin: 40px 0;
}
