.homeWrapper {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: min-content;
  column-gap: 20px;
  margin: 0 24px 24px 24px;
}

.homeTitleHeading {
  color: var(--colorTypoHeadingDefault);
  margin: 0;
  grid-column: 1 / 5;
  grid-row: 1;
  margin: 32px 0px 32px 0px;
  font: var(--semHeadlineDesktopH1);
}

.homeSearchBarContainer {
  grid-column: 1 / 5;
  grid-row: 2;
  margin-bottom: 24px;
}

.homeApplicationsContainer {
  grid-column: 1 / 5;
  grid-row: 3;
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin-bottom: 64px;
  flex-wrap: wrap;
}

.homeActivityContainer {
  grid-column: 1 / 5;
  grid-row: 4;
}

.homeActivityHeading {
  margin-bottom: 32px;
  font: var(--semHeadlineDesktopH1);
  font-size: 32px;
}

.homeActivities {
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.newsEntryContainer {
  display: flex;
  flex-direction: column;
  text-decoration: none;
}

.newsEntryContainer:hover img {
  transform: scale(1.2);
}

.newsEntryContainer:focus img {
  transform: scale(1.2);
}

.newsImageContainer {
  overflow: hidden;
  height: 100px;
}

.newsImageContainer > img {
  height: 100px;
  overflow: hidden;
  width: 100%;
  transform: scale(1);
  transition: 0.2s all ease-in-out;
  object-fit: cover;
}

.newsTitleContainer {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 8px 0 8px 0;
  color: var(--colorTypoHeadingDefault);
  font: var(--semFormTypoInput);
  font-weight: 500;
}

.newsCaptionContainer {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: var(--colorTypoHeadingDefault);
  font: var(--semSnackbarTypoLabel);
}

@media only screen and (min-width: 664px) {
  .homeWrapper {
    grid-template-columns: repeat(8, 1fr);
  }

  .homeTitleHeading {
    grid-column: 3 / 7;
  }

  .homeSearchBarContainer {
    grid-column: 3 / 7;
    margin-bottom: 28px;
  }

  .homeApplicationsContainer {
    grid-column: 3 / 7;
  }

  .homePanelContainer > a {
    max-width: min(360px, calc(50% - 12px));
  }

  .homeActivityContainer {
    grid-column: 3 / 7;
  }
}

@media only screen and (min-width: 1272px) {
  .homeWrapper {
    grid-template-columns: repeat(12, 78px);
    column-gap: 24px;
    margin: 0px;
  }

  .homeTitleHeading {
    grid-column: 4 / 10;
    margin: 46px 0px 40px 0px;
  }

  .homeSearchBarContainer {
    grid-column: 4 / 10;
    margin-bottom: 56px;
  }

  .homeApplicationsContainer {
    grid-column: 4 / 10;
    margin-bottom: 56px;
  }

  .homeActivityContainer {
    grid-column: 4 / 10;
  }

  .homeActivities {
    flex-direction: row;
    gap: 24px;
  }

  .newsImageContainer {
    width: 180px;
    height: 100px;
  }

  .newsEntryContainer {
    width: 180px;
  }
}
