.authErrorPageContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
}

.authErrorPageContents {
  z-index: 20;
  width: calc(100% - 32px);
  height: calc(100% - 32px);
  margin: 16px;
  display: grid;
  grid-template-rows: auto auto auto;
}

.authErrorPageContents > h1 {
  font-size: 56px;
  line-height: 56px;
  text-align: center;
  align-self: start;
  margin-top: 240px;
}

.authErrorPageMessage {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: start;
  justify-self: center;
  align-items: center;
  gap: 16px;
}

.authErrorPageMessage > p {
  font-size: var(--measureFontSizeFontsizeMobileHeadingsH3);
  line-height: var(--measureLineHeightLineheigt24);
}

.authErrorPageMessage a {
  color: var(--semButtonColorPrimaryBackgroundDefault);
  padding: var(--semButtonSpacingPrimaryPaddingTopBottomDefault)
    var(--semButtonSpacingPrimaryPaddingLeftRightDefault);
  font: var(--semButtonTypoPrimaryDefault);
}

.authErrorPageMessage a:hover {
  color: var(--semButtonColorSecondaryLabelHover);
}

.authErrorPageMessage a:active {
  color: var(--semButtonColorSecondaryLabelPressed);
}

.authErrorPageBackgroundContainer {
  z-index: 10;
  position: absolute;
  overflow-y: hidden;
  width: 100%;
  height: 208px;
  background-size: auto 550%;
  background-position: 64% 50%;
}

@media only screen and (min-width: 768px) {
  .authErrorPageContainer {
    justify-content: start;
  }

  .authErrorPageContents {
    width: calc(12 * 80px + 11 * 24px);
    margin: 24px;
    height: calc(100% - 48px);
    display: grid;
    column-gap: 24px;
    grid-template-columns: repeat(12, 80px);
    grid-template-rows: 1fr auto 1fr;
    row-gap: 48px;
    align-items: center;
  }

  .authErrorPageContents > h1 {
    padding-bottom: 24px;
    margin-top: 0;
    font-size: 64px;
    line-height: 64px;
    align-self: end;
    text-align: start;
    grid-row: 1;
    grid-column: 1 / 6;
  }

  .authErrorPageMessage {
    grid-row: 2;
    grid-column: 1 / 5;
  }

  .authErrorPageBackgroundContainer {
    height: 100%;
    left: calc(1272px / 2 - 52px);
    width: calc(50% + 52px);
    background-size: auto;
    background-position: 25% 50%;
    border-radius: 10000px 0 0 10000px;
  }
}

@media only screen and (min-height: 1052px) and (min-width: 768px) {
  .authErrorPageContents {
    grid-template-rows: 1fr auto 1fr;
  }
}

@media only screen and (min-width: 1272px) {
  .authErrorPageContainer {
    justify-content: center;
  }

  .authErrorPageBackgroundContainer {
    left: calc(50% - 52px);
  }
}
